import * as React from "react";
import { FC } from "react";
import { ILocalizedPage } from "../common/types/page";
import { DeathNoticeImages } from "../components/death-notice/death-notice-images";
import {
  Heading,
  HEADING_CLASS
} from "../common/components/typography/heading";
import { useLocalized } from "../common/localization/localize";
import { FancyLine } from "../common/components/lines/fancy-line";

export const DeathNoticeEntrypoint: FC<ILocalizedPage> = ({ lang: _lang }) => {
  const title = useLocalized("death_notice_title");
  return (
    <>
      <Heading level={1} theme={HEADING_CLASS.MAIN}>
        {title}
      </Heading>
      <FancyLine />
      <DeathNoticeImages />
    </>
  );
};
