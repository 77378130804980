import * as React from "react";
import { FC } from "react";
import { useLocalized } from "../../common/localization/localize";
import * as classnames from "classnames";
import classes from "./death-notice-images.module.css";
import spacingsClasses from "../../common/css/spacings.module.css";

export const DeathNoticeImages: FC = () => {
  const images = useLocalized("death_notice_images");
  const altText = useLocalized("death_notice_title");
  return (
    <div className={classes.container}>
      {images.map(image => (
        <a href={`/death-notice/${image}`}>
          <img
            className={classnames(
              spacingsClasses.bottomOuterXxl,
              classes.image
            )}
            alt={altText}
            src={`/death-notice/${image}`}
          />
        </a>
      ))}
    </div>
  );
};
