import * as React from "react";
import { FC } from "react";
import { DeathNoticeEntrypoint } from "../../../entrypoints/death-notice";
import { LANG } from "../../../common/types/localization";
import { DefaultLayout } from "../../../components/layout/default";

export default (): FC => (
  <DefaultLayout
    lang={LANG.plPL}
    entrypoint={"death-notice"}
    page={"posmiertny"}
  >
    <DeathNoticeEntrypoint lang={LANG.plPL} />
  </DefaultLayout>
);
